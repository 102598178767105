
import axios from 'axios'
import { computed, ref } from 'vue'

const currentVersion = ref(null)
const buildVersion = String(process.env.VUE_APP_BUILD_VERSION || '').replace(/\s/g, '').trim()

const isLatest = computed(() => (
  !currentVersion.value
  || !buildVersion
  || buildVersion?.toLowerCase() === 'development'
  || (String(currentVersion.value) == buildVersion)
))

export const setCurrentVersion = (version) => {
  currentVersion.value = String(version || '').replace(/\s/g, '').trim()
}

export const getCurrentVersion = () => {
  axios.get(`https://dashboard.taxiboeken.be/build.txt?_t=${Date.now()}`).then(({ data }) => {
    setCurrentVersion(data)
  }).catch((error) => {
    setCurrentVersion('?')
    console.error({ error })
  })
}

export default function isLatestVersion() {
  if (!currentVersion.value) {
    getCurrentVersion()
  }

  return isLatest
}
