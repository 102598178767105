function obfs(str, key, n) {
  if (!(typeof key === 'number' && key % 1 === 0) || !(typeof key === 'number' && key % 1 === 0)) {
    return str.toString()
  }
  var chars = str.toString().split('')
  for (var i = 0; i < chars.length; i++) {
    var c = chars[i].charCodeAt(0)
    if (c <= n) {
      chars[i] = String.fromCharCode((chars[i].charCodeAt(0) + key) % n)
    }
  }
  return chars.join('')
}

const fromB = (str = '') => {
  if (typeof window === 'undefined') {
    return Buffer.from(str, 'base64').toString()
  }

  return window.atob(str)
}

const toB = (str = '') => {
  if (typeof window === 'undefined') {
    return Buffer.from(str, 'utf-8').toString('base64')
  }

  return window.btoa(str)
}

export default client => {
  const name = fromB('WC1IYXNo')

  client.interceptors.request.use(
    config => {
      config.headers = config.headers || {}
      const d = new Date()
      const random = Math.floor(Math.random() * 100)
      const parts = [d.getMilliseconds(), d.toISOString(), d.getTime(), d.getSeconds(), random].join(';;')
      config.headers[name] = toB(obfs(parts, 64, 126))
      return config
    },
    error => {
      Promise.reject(error)
    },
  )
}
