import clone from 'just-clone'
// import sharedLib from '@/assets/taxiboeken-shared'
import { BEREKENINGEN_BY_COMPANY, COMPANY_MAPPING, getBerekening } from '@/assets/berekening/lib.min'

export const PERSONA = () => [
  { value: null, label: 'Geen persona opgeven' },
  { value: 'CEO', label: 'CEO' },
  { value: 'REISBUREAU', label: 'REISBUREAU' },
]

export const TYPE_VERVOER = () => [
  { value: 'gedeeld', label: 'Comfort/Gedeeld vervoer' },
  { value: 'prive', label: 'Privé vervoer' },
  // { value: 'budget', label: 'Budget vervoer' },
  { value: 'luxe', label: 'Luxe vervoer' },
]

export const KALENDER_FILTERS = () => [
  { value: null, label: 'Alles tonen', nullableItem: true, filterFn: () => true },
  { value: 'MET_FACTUUR', label: 'Met factuur', filterFn: (record) => record?.rit?.is_factuur_nodig || !!record?.rit?.facturatie_id },
  { value: 'ZONDER_FACTUUR', label: 'Zonder factuur', filterFn: (record) => !record?.rit?.is_factuur_nodig && !record?.rit?.facturatie_id },
  { value: 'BOETES', label: 'Boetes', filterFn: (record) => record?.rit?.is_boete },
  { value: 'PRIVE', label: 'Privé vervoer', filterFn: (record) => record?.rit?.kost_prive_vervoer > 0 },
  { value: 'PREMIUM', label: 'Premium vervoer', filterFn: (record) => record?.rit?.kost_luxe_vervoer > 0 },
  {
    value: 'CHIRON_NIET_VERSTUURD',
    label: 'Chiron niet/deels verstuurd',
    filterFn: (record) => {
      return !!record?.chauffeurs?.find(el => !el.shift_chiron_status)
    },
  },
  {
    value: 'SHIFT_NIET_VERSTUURD',
    label: 'Shift niet/deels ingevuld',
    filterFn: (record) => {
      return !!record?.chauffeurs?.find(el => !el.shift_start)
    },
  },
]

export const HEATMAP_BLEND_LEVEL = () => [
  { value: 0.01, label: 'Level 1' },
  { value: 0.02, label: 'Level 2' },
  { value: 0.03, label: 'Level 3' },
  { value: 0.04, label: 'Level 4' },
  { value: 0.05, label: 'Level 5' },
]

export const LANGUAGE = () => [
  { value: 'nl', label: 'Nederlands' },
  { value: 'fr', label: 'Frans' },
  { value: 'en', label: 'Engels' },
]

export const RIT_OPVOLGING = () => [
  { value: 1, label: 'Opbellen' },
  { value: 2, label: 'Mailen' },
  { value: 3, label: 'Betalingen' },
  { value: 99, label: 'Andere' },
]

export const USER_TYPE = () => [
  { value: 'chauffeur', label: 'Chauffeur' },
  { value: 'onderaannemer', label: 'Onderaannemer' },
  { value: 'kantoor', label: 'Kantoor' },
  { value: 'boekhouder', label: 'Boekhouder' },
]

export const BLACKLIST_WHITELIST_TYPE = () => [
  { value: 'PHONE', label: 'Telefoon' },
  { value: 'EMAIL', label: 'Email' },
  { value: 'EMAIL_DOMAIN', label: 'Email domein' },
]

export const BETAALMETHODE = () => [
  { value: 'CASH', label: 'Cash' },
  { value: 'ONLINE', label: 'Online' },
  { value: 'OVERSCHRIJVING', label: 'Overschrijving' },
  // { value: 'BETAALTERMINAL', label: 'Betaalterminal' },
]

export const BETAALMETHODE_FACTUUR = () => [
  { value: 'CASH', label: 'Cash' },
  { value: 'ONLINE', label: 'Online' },
  { value: 'OVERSCHRIJVING', label: 'Overschrijving' },
  { value: 'CREDIT_NOTE', label: 'Krediet nota' },
  // { value: 'BETAALTERMINAL', label: 'Betaalterminal' },
]

export const BTW_PERCENT = () => [
  { value: 0, label: '0 %' },
  { value: 3, label: '3 %' },
  { value: 6, label: '6 %' },
  { value: 12, label: '12 %' },
  { value: 16, label: '16 %' },
  { value: 18, label: '18 %' },
  { value: 21, label: '21 %' },
]

export const AUTOMERK = () => [
  'Abarth',
  'Aiways',
  'Alfa Romeo',
  'Alpine',
  'Artega',
  'Aston Martin',
  'Audi',
  'BAIC',
  'Bentley',
  'Bestune',
  'BMW',
  'BMW Alpina',
  'Bugatti',
  'Cadillac',
  'Caterham',
  'Chevrolet',
  'Chrysler',
  'Citroën',
  'Cupra',
  'Dacia',
  'Daihatsu',
  'Dodge',
  'Donkervoort',
  'DS',
  'DSFK',
  'Ferrari',
  'Fiat',
  'Ford',
  'Genesis',
  'Honda',
  'Hummer',
  'Hyundai',
  'Infiniti',
  'Isuzu',
  'Iveco',
  'Jaguar',
  'Jeep',
  'Kia',
  'KTM',
  'Lada',
  'Lamborghini',
  'Lancia',
  'Land Rover',
  'Lexus',
  'Lightyear',
  'Lotus',
  'Lynk & Co',
  'Maserati',
  'Mazda',
  'McLaren',
  'Mercedes-Benz',
  'MG',
  'Mia Electric',
  'MINI',
  'Mitsubishi',
  'Morgan',
  'Nissan',
  'Opel',
  'Peugeot',
  'Polestar',
  'Porsche',
  'Renault',
  'Rolls-Royce',
  'Saab',
  'SEAT',
  'Skoda',
  'Smart',
  'SsangYong',
  'Subaru',
  'Suzuki',
  'Tesla',
  'Toyota',
  'TVR',
  'Volkswagen',
  'Volvo',
]

export const AFWEZIGHEID_REASON = () => {
  return ['AANGEVRAAGD', 'ANDERE', 'FAMILIE', 'GEEN_SHIFT', 'VAKANTIE', 'ZIEKTE']
}

export const KOSTEN_SOORT = (opts = {}) => {
  const { all } = opts
  const soorten = ['VARIABLE', 'VAST', 'EENMALIG'].map(value => ({ label: value, value }))
  if (all) soorten.unshift({ label: 'Alle soorten', value: null })
  return soorten
}

export const BOEKING_TYPE = () => [
  { value: 'ALLES', label: 'Alle reservaties' },
  { value: 'ONBETAALD', label: 'Onbetaald bevestigde reservaties' },
  { value: 'BEVESTIGD', label: 'Bevestigde reservaties' },
  { value: 'GEANNULEERD', label: 'Geannuleerde reservaties' },
  { value: 'ONBEVESTIGD', label: 'Onbevestigde reservaties' },
  { value: 'OFFERTES', label: 'Openstaande offertes' },
]

export const MAIL_SJABLONEN_FACTUUR = () => [
  { label: 'FACTUUR', value: 'FACTUUR' },
  { label: 'FACTUUR EERSTE HERINNERING', value: 'FACTUUR_EERSTE_HERINNERING' },
  { label: 'FACTUUR TWEEDE HERINNERING', value: 'FACTUUR_TWEEDE_HERINNERING' },
  { label: 'ADVOCAAT MAIL', value: 'FACTUUR_ADVOCAAT' },
]

export const MAIL_SJABLONEN_CREDIT_NOTE = () => [
  { label: 'KREDIET NOTA', value: 'CREDIT_NOTE' },
]

export const STATS_KLANTEN_OP_BASIS_VAN = () => [
  { label: 'Reservaties', value: 'reservaties' },
  { label: 'Privé reservaties', value: 'prive_reservaties' },
  { label: 'Last minutes', value: 'last_minutes' },
  { label: 'Annulaties', value: 'annulaties' },
  { label: 'Totaal boekingswaarde', value: 'boekingswaarde' },
  { label: 'Gem. boekingswaarde', value: 'avg_boekingswaarde' },
  { label: 'Acties (Promo codes)', value: 'acties' },
  { label: 'Totaal korting', value: 'korting' },
  { label: 'Totaal korting promoties', value: 'korting_promotie' },
  { label: 'Offertes gestuurd', value: 'offertes' },
  { label: 'Offertes ingegaan', value: 'offertes_ingegaan' },
]

export const STATS_PERSONEEL_OP_BASIS_VAN = () => [
  { label: 'Aantal enkele ritten', value: 'enkele_ritten' },
  { label: 'Getekende contracten (Digitaal)', value: 'getekend' },
  { label: 'Gecombineerde ritten', value: 'combinaties' },
  { label: 'Last minutes', value: 'last_minutes' },
  { label: 'Wakeup calls', value: 'wakeup_calls' },
  { label: 'Kilometers (Google maps)', value: 'kilometers' },
  { label: 'Tijd (Google maps)', value: 'seconden' },
  { label: 'Loon', value: 'loon' },
  // { label: 'Score simulatie', value: 'score' }, // @TODO: score bepalen/berekenen in api en dan hier uncomment
]

export const RIT_AANVRAAG = () => [
  { value: 'WEBSITE', label: 'Via website', disabled: true },
  { value: 'PHONE', label: 'Via telefoon' },
  { value: 'MAIL', label: 'Via mail' },
  { value: 'ONLINE_CHAT', label: 'Via online chat (Tawk)' },
  { value: 'WHATSAPP', label: 'Via whatsapp' },
  { value: 'FACEBOOK', label: 'Via Facebook/Messenger' },
  { value: 'INSTAGRAM', label: 'Via Instagram' },
  { value: 'ONDERAANNEMING', label: 'Via onderaannemer' },
]

export const CALL_LABELS = () => [
  { value: 'WENST_GEEN_OFFERTE', label: 'Wenst geen offerte' },
  { value: 'OFFERTE_VERZONDEN', label: 'Offerte verzonden' },
  { value: 'GERESERVEERD', label: 'Gereserveerd' },
  { value: 'BESTAANDE_RESERVATIE', label: 'Bestaande reservatie' },
  { value: 'PROBLEEM', label: 'Probleem' },
  { value: null, label: '---', disabled: true },
  { value: 'SOLLICITANT', label: 'Sollicitant' },
  { value: 'ADMINISTRATIE', label: 'Administratie' },
  { value: null, label: '---', disabled: true },
  { value: 'AUTOHANDELAAR', label: 'Autohandelaar' },
  { value: 'VERKOPER_TELEMARKETING', label: 'Verkoper / telemarketing' },
  { value: 'VERKEERD_VERBONDEN', label: 'Verkeerd verbonden' },
]

export const EXTERNAL_REVIEWSITES = () => [
  { value: 'GOOGLE', label: 'Google' },
  { value: 'FACEBOOK', label: 'Facebook' },
  { value: 'TRIPADVISOR', label: 'Tripadvisor' },
  { value: 'TRUSTPILOT', label: 'Trustpilot' },
  { value: 'YELP', label: 'Yelp' },
  { value: 'OTHER', label: 'Andere' },
]

export const DASHBOARD_VIEWS = () => {
  const list = [
    'CHAUFFEUR_CHAMPIONS_LEAGUE',
  ].map((value) => ({ value, label: value.split('_').join(' ') }))

  return list
}

export const FINANCIAL_JOURNALS = [
  { label: 'Luchthavenvervoer Facturatie', value: 1, mask: '####-####' },
  { label: 'BQT (Mutas)', value: 2, mask: '########', prefix: 'BQT' },
  { label: 'Kevalie', value: 3, disabled: true, mask: '####-####' },
]

export const BEDRIJVEN_THEMA = () => [
  { value: 'LUCHTHAVENVERVOERKEVIN', label: 'Luchthavenvervoer Kevin' },
  { value: 'BAIORO', label: 'Baioro' },
  { value: 'TAXILUCHTHAVEN', label: 'Taxi Luchthaven (Overname)' },
  { value: 'WATKOSTEENTAXI', label: 'Wat kost een taxi' },
  { value: 'WATKOSTLUCHTHAVENVERVOER', label: 'Wat kost luchthavenvervoer' },
]

export const RIT_BEREKENING = (opts = {}) => {
  const { bedrijf_thema } = opts
  const result = []

  if (!bedrijf_thema) return result

  const company = COMPANY_MAPPING[bedrijf_thema] || bedrijf_thema
  const latest = getBerekening(company)
  const list = BEREKENINGEN_BY_COMPANY[company]

  Object.entries(list).forEach(([k, fn]) => {
    const isLatest = !!(latest === fn)
    const version = String(isLatest ? `${k} (HUIDIG)` : k)
    result.push({
      label: `${company} ${version}`,
      value: String(k).replace('v', ''),
      company,
      isLatest,
    })
  })

  return result
}

let WACHTIJD_LIST = []
export const WACHTTIJD = () => {
  if (WACHTIJD_LIST.length) return WACHTIJD_LIST

  const list = []
  const d = new Date(2000, 1, 1, 0, 0, 0, 1)
  for (let i = 1; i <= 81; i += 1) {
    const h = String(d.getHours()).padStart(2, '0')
    const m = String(d.getMinutes()).padStart(2, '0')
    d.setTime(d.getTime() + (60000 * 15))
    list.push({ value: `${h}:${m}`, label: `${h}h ${m}m` })
  }

  WACHTIJD_LIST = list
  return WACHTIJD_LIST
}

export const STATISTIEKEN_D = () => ['DAGELIJKS']
export const STATISTIEKEN_DMY = () => ['DAGELIJKS', 'MAANDELIJKS', 'JAARLIJKS']
export const STATISTIEKEN_DWMY = () => ['DAGELIJKS', 'WEKELIJKS', 'MAANDELIJKS', 'JAARLIJKS']
export const STATISTIEKEN_MY = () => ['MAANDELIJKS', 'JAARLIJKS']
export const STATISTIEKEN_M = () => ['MAANDELIJKS']
export const STATISTIEKEN_Y = () => ['JAARLIJKS']

export const MAANDEN = [
  { value: 1, label: 'Januari' },
  { value: 2, label: 'Februari' },
  { value: 3, label: 'Maart' },
  { value: 4, label: 'April' },
  { value: 5, label: 'Mei' },
  { value: 6, label: 'Juni' },
  { value: 7, label: 'Juli' },
  { value: 8, label: 'Augustus' },
  { value: 9, label: 'September' },
  { value: 10, label: 'Oktober' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
]

export const MAAND = () => clone(MAANDEN)

export const WEEKDAGEN = [
  { value: 1, label: 'Maandag' },
  { value: 2, label: 'Dinsdag' },
  { value: 3, label: 'Woensdag' },
  { value: 4, label: 'Donderdag' },
  { value: 5, label: 'Vrijdag' },
  { value: 6, label: 'Zaterdag' },
  { value: 7, label: 'Zondag' },
]

export const WEEKDAG = () => WEEKDAGEN

// --------------------------------------------------

export const LABELS = {
  PERSONA: 'Persona',
  TYPE_VERVOER: 'Type vervoer',
  KALENDER_FILTERS: 'Filters',
  HEATMAP_BLEND_LEVEL: 'Blend level',
  LANGUAGE: 'Taal selecteren',
  RIT_OPVOLGING: 'Opvolging',
  USER_TYPE: 'Gebruiker type',
  BLACKLIST_WHITELIST_TYPE: 'White-/Blacklist type',
  BETAALMETHODE: 'Betaalmethode',
  BETAALMETHODE_FACTUUR: 'Betaalmethode',
  BTW_PERCENT: 'BTW %',
  AFWEZIGHEID_REASON: 'Afwezigheid reden',
  AUTOMERK: 'Automerk',
  KOSTEN_SOORT: 'Kosten soort',
  BOEKING_TYPE: 'Boeking type',
  MAIL_SJABLONEN_FACTUUR: 'Sjabloon',
  MAIL_SJABLONEN_CREDIT_NOTE: 'Sjabloon',
  RIT_AANVRAAG: 'Aangevraagd via',
  BEDRIJVEN_THEMA: 'Bedrijfs thema',
  RIT_BEREKENING: 'Rit berekening',
  CALL_LABELS: 'Call label',
  STATISTIEKEN_D: 'Weergave type',
  STATISTIEKEN_DMY: 'Weergave type',
  STATISTIEKEN_DWMY: 'Weergave type',
  STATISTIEKEN_MY: 'Weergave type',
  STATISTIEKEN_M: 'Weergave type',
  STATISTIEKEN_Y: 'Weergave type',
  STATS_KLANTEN_OP_BASIS_VAN: 'Op basis van',
  STATS_PERSONEEL_OP_BASIS_VAN: 'Op basis van',
  EXTERNAL_REVIEWSITES: 'Externe reviewsite',
  DASHBOARD_VIEWS: 'Bekeken item/topic',
  MAAND: 'Maand',
  WEEKDAG: 'Weekdag',
  WACHTTIJD: 'Wachttijd',
  FINANCIAL_JOURNALS: 'Dagboek (Financieel)',
}

export default {
  PERSONA,
  TYPE_VERVOER,
  KALENDER_FILTERS,
  HEATMAP_BLEND_LEVEL,
  LANGUAGE,
  RIT_OPVOLGING,
  USER_TYPE,
  BLACKLIST_WHITELIST_TYPE,
  BETAALMETHODE,
  BETAALMETHODE_FACTUUR,
  BTW_PERCENT,
  AUTOMERK,
  AFWEZIGHEID_REASON,
  KOSTEN_SOORT,
  BOEKING_TYPE,
  MAIL_SJABLONEN_FACTUUR,
  MAIL_SJABLONEN_CREDIT_NOTE,
  RIT_AANVRAAG,
  BEDRIJVEN_THEMA,
  RIT_BEREKENING,
  CALL_LABELS,
  STATISTIEKEN_D,
  STATISTIEKEN_DMY,
  STATISTIEKEN_DWMY,
  STATISTIEKEN_MY,
  STATISTIEKEN_M,
  STATISTIEKEN_Y,
  STATS_KLANTEN_OP_BASIS_VAN,
  STATS_PERSONEEL_OP_BASIS_VAN,
  EXTERNAL_REVIEWSITES,
  DASHBOARD_VIEWS,
  MAAND,
  WEEKDAG,
  WACHTTIJD,
  FINANCIAL_JOURNALS,
}
