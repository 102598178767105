<template>
  <div v-if="vModel" class="flex flex-col gap-4">
    <UISelectChauffeur v-model="form.chauffeur_id" class="max-w-xs" label="Chauffeur" />
    <template v-if="form.shift_id">
      <UISelectWagen label="Wagen" class="w-full max-w-sm" v-model="form.wagen_id" :key="form.wagen_id" />
      <UIInputDatePicker label="Start van je shift" class="text-left z-90" timepicker v-model:date="form.start.date" v-model:time="form.start.time" />
      <UIInputDatePicker :label="labelAangekomen" class="text-left z-90" timepicker v-model:date="form.aangekomen.date" v-model:time="form.aangekomen.time" />
      <UIInputDatePicker :disabled="chiron_status === 'VERTREK' || chiron_status === 'AANKOMST'" label="Klant ingestapt in wagen" class="text-left z-90" timepicker v-model:date="form.klant_ingestapt.date" v-model:time="form.klant_ingestapt.time" />
      <UIInputDatePicker :disabled="chiron_status === 'AANKOMST'" label="Klant afgezet op eindbestemming" class="text-left z-90" timepicker v-model:date="form.klant_afgezet.date" v-model:time="form.klant_afgezet.time" />
      <UIInputDatePicker label="Einde van je shift" class="text-left z-90" timepicker v-model:date="form.einde.date" v-model:time="form.einde.time" />
    </template>
    <div v-else>
      Geen shift gekoppeld aan deze toekenning, dus enkel chauffeur aanpasbaar.
    </div>

    <div class="inline-flex flex-col gap-1 mt-2">
      <small>Belgische tijd invullen!</small>
      <button class="btn success inline-block" @click="handleOpslaan" :disabled="!form.shift_id">Opslaan</button>
    </div>

    <small>Shift #{{ form.shift_id }}</small>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue'
import { useVModel } from '@vueuse/core'

import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UISelectWagen from '@/components/UI/Select/Wagen.vue'

import { dateString, timeString, dateAndTimeToUTC } from '@/functions/formatDate'
import useApi from '@/hooks/useApi'

const props = defineProps({
  callback: Function,
  planning_id: Number,
  isOphaalLuchthaven: Boolean,
  modelValue: {
    type: Object,
    default() {
      return {
        planning_id: null,
        chauffeur_id: null,
        shift_id: null,
        wagen_id: null,
        start: null,
        aangekomen: null,
        klant_ingestapt: null,
        klant_afgezet: null,
        einde: null,
        chiron_status: null,
      }
    },
  },
})

const labelAangekomen = computed(() => props.isOphaalLuchthaven ? 'Aangekomen op luchthaven (ophaallocatie)' : 'Aangekomen op ophaallocatie')

const emit = defineEmits(['update:shift', 'close'])
const vModel = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

const chiron_status = computed(() => props.modelValue?.chiron_status?.toUpperCase())

const formTmp = {
  planning_id: vModel.value.planning_id || null,
  chauffeur_id: vModel.value.chauffeur_id || null,
  shift_id: vModel.value.shift_id || null,
  wagen_id: vModel.value.wagen_id || null,
  start: vModel.value.start || new Date(),
  aangekomen: vModel.value.aangekomen || (vModel.value.start ? new Date() : null),
  klant_ingestapt: vModel.value.klant_ingestapt || (vModel.value.aangekomen ? new Date() : null),
  klant_afgezet: vModel.value.klant_afgezet || (vModel.value.klant_ingestapt ? new Date() : null),
  einde: vModel.value.einde || (vModel.value.klant_afgezet ? new Date() : null),
}

const form = ref({
  planning_id: formTmp.planning_id || null,
  chauffeur_id: formTmp.chauffeur_id || null,
  shift_id: formTmp.shift_id || null,
  wagen_id: formTmp.wagen_id || null,

  start: !formTmp.start
    ? { date: null, time: null }
    : { date: dateString(formTmp.start), time: timeString(formTmp.start || '00:00') },

  aangekomen: !formTmp.aangekomen
    ? { date: null, time: null }
    : { date: dateString(formTmp.aangekomen), time: timeString(formTmp.aangekomen || '00:00') },

  klant_ingestapt: !formTmp.klant_ingestapt
    ? { date: null, time: null }
    : { date: dateString(formTmp.klant_ingestapt), time: timeString(formTmp.klant_ingestapt || '00:00') },

  klant_afgezet: !formTmp.klant_afgezet
    ? { date: null, time: null }
    : { date: dateString(formTmp.klant_afgezet), time: timeString(formTmp.klant_afgezet || '00:00') },

  einde: !formTmp.einde
    ? { date: null, time: null }
    : { date: dateString(formTmp.einde), time: timeString(formTmp.einde || '00:00') },
})

const api = useApi()

const handleOpslaan = () => {
  const data = {
    chauffeur_id: form.value.chauffeur_id || null,
    planning_id: form.value.planning_id || null,
    shift_id: form.value.shift_id || null,
    wagen_id: form.value.wagen_id || null,
    start: dateAndTimeToUTC(form.value.start.date, form.value.start.time || '00:00'),
    aangekomen: dateAndTimeToUTC(form.value.aangekomen.date, form.value.aangekomen.time || '00:00'),
    klant_ingestapt: dateAndTimeToUTC(form.value.klant_ingestapt.date, form.value.klant_ingestapt.time || '00:00'),
    klant_afgezet: dateAndTimeToUTC(form.value.klant_afgezet.date, form.value.klant_afgezet.time || '00:00'),
    einde: dateAndTimeToUTC(form.value.einde.date, form.value.einde.time || '00:00'),
  }

  if (data.start && data.aangekomen && data.start > data.aangekomen) {
    return alert('De opgegeven datums/tijden zijn niet chronologisch en dus ook verkeerd. Kijk je opgegeven datums en tijden na.')
  }
  if (data.aangekomen && data.klant_ingestapt && data.aangekomen > data.klant_ingestapt) {
    return alert('De opgegeven datums/tijden zijn niet chronologisch en dus ook verkeerd. Kijk je opgegeven datums en tijden na.')
  }
  if (data.klant_ingestapt && data.klant_afgezet && data.klant_ingestapt > data.klant_afgezet) {
    return alert('De opgegeven datums/tijden zijn niet chronologisch en dus ook verkeerd. Kijk je opgegeven datums en tijden na.')
  }
  if (data.klant_afgezet && data.einde && data.klant_afgezet > data.einde) {
    return alert('De opgegeven datums/tijden zijn niet chronologisch en dus ook verkeerd. Kijk je opgegeven datums en tijden na.')
  }

  api('TOEKKENNING_BEWERKING', data).then(() => {
    if (typeof props.callback === 'function') {
      props.callback(data)
    }
    emit('close')
  })
}
</script>
