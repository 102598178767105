import useWindow from '@/hooks/useWindow'
import { getCurrentVersion } from '@/hooks/useCheckLatestVersion'

// const handleRegistration = async (registration) => {
//   if (typeof registration?.update === 'function') {
//     await registration.update()
//   }
//   if (registration?.waiting?.postMessage) {
//     registration.waiting.postMessage({ type: 'SKIP_WAITING' })
//   }
//   if (registration?.active?.postMessage) {
//     registration.active.postMessage({ type: 'SKIP_WAITING' })
//   }
// }

export default async (cb = null) => {
  if (!useWindow()) {
    if (typeof cb === 'function') cb()
    return
  }

  // Caches
  if (window.caches) {
    const cacheNames = await window.caches?.keys()
    for (let name of cacheNames) {
      const caching = await caches.open(name)
      const keys = await caching.keys()
      for (let key of keys) {
        await caching.delete(key)
      }
      await window.caches.delete(name)
    }
  }

  // ServiceWorker
  // if (window.navigator?.serviceWorker?.getRegistration) {
  //   let registration = await window.navigator.serviceWorker.getRegistration()
  //   await handleRegistration(registration)
  // }

  // if (window.navigator?.serviceWorker?.getRegistrations) {
  //   const registrations = await window.navigator.serviceWorker.getRegistrations()
  //   for (let registration of registrations) {
  //     await handleRegistration(registration)
  //   }
  // }

  // Scripts and Links
  const els = document.querySelectorAll('a, link, script, img')
  const now = Date.now()
  for (let i = 0; i < els.length; i++) {
    const tag = els[i]
    try {
      if (!tag.href || tag.src) continue
      let url = new URL(tag.href || tag.src)
      url.searchParams.set('_t', now)
      tag.href = url.toString()
      tag.src = tag.href
    } catch (e) {
      console.warn(e, tag)
    }
  }

  getCurrentVersion()

  // Cache bust through fetch
  if (typeof fetch === 'function') {
    setImmediate(() => {
      fetch(window.location.href, {
        headers: {
          Pragma: 'no-cache',
          Expires: '-1',
          'Cache-Control': 'no-cache',
        },
      }).finally(() => {
        if (typeof cb === 'function') cb()
      })
    })
    return
  }

  if (typeof cb === 'function') cb()
}
