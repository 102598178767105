import { DateTime, Duration } from 'luxon'
import { WEEKDAGEN } from '@/constants/OPTIONS'
import { capitalize } from '@/functions/formatText'

export const resetTime = (date) => {
  const d = new Date(date || Date.now())
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

export const daysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate()
}

export const dateAndTimeToUTC = (date, time = '00:00') => {
  if (date === null) return null
  return DateTime.fromFormat(`${dateString(date)} ${time}`, 'yyyy-MM-dd T', {
    zone: 'Europe/Paris',
  })
    .setZone('Europe/Paris')
    .toUTC()
    .toString()
}

export const timeString = (date) => {
  if (String(date).match(/^[0-9]{2}:[0-9]{2}:[0-9]{2}/gi)) {
    return String(date).split(':').slice(0, 2).join(':')
  }
  if (String(date).match(/^[0-9]{1,2}:[0-9]{1,2}$/gi)) {
    return String(date).split(':').map(part => String(part).padStart(2, '0')).join(':')
  }
  return DateTime.fromMillis(new Date(date || Date.now()).getTime())
    .setZone('Europe/Paris')
    .toFormat('T')
}

export const dayOfWeekString = (date) => {
  if (date >= 0 && date <= 7) {
    return WEEKDAGEN[date - 1].label
  }

  const weekday = DateTime.fromMillis(new Date(date || Date.now()).getTime())
    .setZone('Europe/Paris')
    .weekday()
  return WEEKDAGEN[weekday - 1].label
}

export const dateString = (date) => {
  if (String(date).match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/gi)) {
    return String(date)
  }
  if (String(date).match(/^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/gi)) {
    return date.split('-').map(part => String(part).padStart(2, '0')).join('-')
  }
  return DateTime.fromMillis(new Date(date || Date.now()).getTime())
    .setZone('Europe/Paris')
    .toFormat('yyyy-MM-dd')
}

export const dateTimeString = (date) => {
  return `${dateString(date)} ${timeString(date)} `
}

export const dateWrittenTimeString = (date) => {
  return `${dateWritten(date)} ${timeString(date)} `
}

export const dateStringLocale = (date) => {
  if (String(date).match(/^[0-9]{4}-[0-9]{1}-[0-9]{2}$/gi) || String(date).match(/^[0-9]{4}-[0-9]{2}-[0-9]{1}$/gi)) {
    return dateStringLocale(dateString(date))
  }
  return DateTime.fromMillis(new Date(date || Date.now()).getTime())
    .setZone('Europe/Paris')
    .setLocale('nl')
    .toFormat('dd MMMM yyyy')
}

export const monthToString = (month) => {
  return DateTime.fromMillis(new Date(2020, Number(month) - 1, 1).getTime())
    .setZone('Europe/Paris')
    .setLocale('nl')
    .toFormat('MMMM')
}

export const dateTimeStringLocale = (date) => {
  return DateTime.fromMillis(new Date(date || Date.now()).getTime())
    .setZone('Europe/Paris')
    .setLocale('nl')
    .toFormat('dd MMM yyyy HH:mm')
}

export const timeStringLocale = (date) => {
  return DateTime.fromMillis(new Date(date || Date.now()).getTime())
    .setZone('Europe/Paris')
    .setLocale('nl')
    .toFormat('HH:mm')
}

export const dateWritten = (date) => {
  const d = resetTime(new Date(date || Date.now()))
  const now = resetTime(new Date())
  const dateTime = DateTime.fromMillis(d.getTime())
    .setZone('Europe/Paris')
    .setLocale('nl')

  const diff = dateTime.diff(DateTime.fromMillis(now.getTime()), 'days')
  if (diff.days >= -2 && diff.days <= 2) {
    // return dateTime.toRelativeCalendar({ units: ['days'] })

    const base = DateTime.fromMillis(1642201200000)
      .setZone('Europe/Paris')
      .setLocale('nl')
    return capitalize(base.plus({ days: diff.days }).toRelativeCalendar({ base }))
  }

  return dateStringLocale(date)
}

export const isDate = (date) => {
  if (!date) return false
  return new Date(date).toString() !== 'Invalid Date'
}

export const millisecondsToHMS = (milliseconds = 0) => {
  return Duration.fromObject({ milliseconds: Number(milliseconds) }).toFormat('hh:mm:ss') //=> '00:00:60'
}

export const millisecondsToHMSWritten = (milliseconds = 0) => {
  const parts = millisecondsToHMS(milliseconds || 0).split(':')
  return `${parts[0]}h ${parts[1]}m`
}

export const secondsToHMS = (seconds = 0) => {
  return millisecondsToHMS((seconds || 0) * 1000)
}

export const secondsToHMSWritten = (seconds = 0) => {
  return millisecondsToHMSWritten((seconds || 0) * 1000)
}

export const isVerleden = (d, nowMinusOffset = 0) => {
  const now = Date.now()
  return new Date(d).getTime() < now - nowMinusOffset
}
